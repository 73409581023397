import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import authApi from './services/authApi';
import usersApi from '../features/users/usersApi';
import appReducer from './appSlice';
import treeApi from './services/treeApi';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [treeApi.reducerPath]: treeApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      treeApi.middleware,
      authApi.middleware,
      usersApi.middleware
    )
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
