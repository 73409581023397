import { Edge, Node } from 'react-flow-renderer';
import { UserTreeNodeStatus } from '../../app/services/treeApi';

export const position = { x: 0, y: 0 };

export type DiagramNodeOnChange = (
  id: string,
  data: { status: UserTreeNodeStatus | null }
) => void;

export interface DiagramNodeData {
  title: string;
  color?: string;
  status?: UserTreeNodeStatus | null;
  onChange?: DiagramNodeOnChange;
  hasChildren?: boolean;
  isRoot?: boolean;
}

export interface DiagramNode extends DiagramNodeData {
  id: string;
  nodes?: DiagramNode[];
}

export interface DiagramParentNode extends DiagramNode {
  id: string;
}

interface MapTreeToNodesOptions {
  onChange: DiagramNodeOnChange;
}

const createPhantom = (nodeId: string, parentNodeId?: string) => {
  const id = `${nodeId}-phantom`;

  const phantomNode = {
    id,
    data: {
      title: 'top'
    },
    position,
    type: 'statusSelect',
    hidden: true
  };

  if (parentNodeId) {
    const phantomEdge = {
      id: `e${parentNodeId}${id}`,
      source: parentNodeId,
      target: id,
      type: 'custom',
      hidden: true
    };

    return { phantomNode, phantomEdge };
  }

  return { phantomNode };
};

export const mapTreeToNodes = (
  tree?: DiagramNode,
  options?: MapTreeToNodesOptions
) => {
  const initialNodes: Node<DiagramNodeData>[] = [];
  const initialEdges: Edge[] = [];

  const mapNodes = (
    node: DiagramNode,
    parentNode?: DiagramParentNode,
    phantomNeighbor?: 'top' | 'bottom'
  ) => {
    if (node.nodes) {
      node.nodes.forEach((childNode, i) => {
        mapNodes(
          childNode,
          {
            ...node,
            color: node.color || parentNode?.color || ''
          },
          i === 0
            ? 'top'
            : node.nodes && i === node.nodes.length - 1
            ? 'bottom'
            : undefined
        );
      });
    }

    if (phantomNeighbor === 'top') {
      const { phantomNode, phantomEdge } = createPhantom(
        node.id,
        parentNode?.id
      );

      initialNodes.push(phantomNode);

      if (phantomEdge) {
        initialEdges.push(phantomEdge);
      }
    }

    initialNodes.push({
      id: node.id,
      data: {
        title: node.title,
        color: node.color || parentNode?.color,
        status: node.status,
        onChange: options?.onChange,
        hasChildren: !!node.nodes?.length,
        isRoot: !parentNode
      },
      position,
      type: 'statusSelect'
    });

    if (parentNode) {
      initialEdges.push({
        id: `${parentNode.id}-${node.id}`,
        source: parentNode.id,
        target: node.id,
        type: 'custom',
        style: {
          stroke: node.color || parentNode.color,
          strokeWidth: 2
        }
      });
    }

    if (phantomNeighbor === 'bottom') {
      const { phantomNode, phantomEdge } = createPhantom(
        node.id,
        parentNode?.id
      );

      initialNodes.push(phantomNode);

      if (phantomEdge) {
        initialEdges.push(phantomEdge);
      }
    }
  };

  if (tree) {
    mapNodes(tree);
  }

  return { initialNodes, initialEdges };
};
