import { useContext, useEffect } from 'react';
import { ConfirmOptions } from './ConfirmTypes';
import { ConfirmContext } from './ConfirmProvider';

const useConfirm = (options?: ConfirmOptions) => {
  const { open, setOptions } = useContext(ConfirmContext);

  useEffect(() => {
    setOptions(options || {});
  }, [options, setOptions]);

  return open;
};

export default useConfirm;
