import { TreeItem } from 'react-sortable-tree';
import { TreeNode } from '../../app/services/treeApi';

export const mapTreeNodesToItems = (
  items: TreeNode[],
  deep = 0
): TreeItem[] => {
  return items.map(item => ({
    title: item.title,
    color: item.color,
    expanded: true,
    children: Array.isArray(item.nodes)
      ? mapTreeNodesToItems(item.nodes, deep + 1)
      : []
  }));
};

export const mapTreeItemsToNodes = (items: TreeItem[]): TreeNode[] => {
  return items.map(item => ({
    color: item.color,
    title: typeof item.title === 'string' ? item.title : '',
    nodes: Array.isArray(item.children)
      ? mapTreeItemsToNodes(item.children)
      : []
  }));
};
