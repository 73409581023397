import React, { useState } from 'react';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  defaultGetNodeKey,
  getNodeAtPath,
  removeNodeAtPath,
  TreeItem
} from 'react-sortable-tree';
import './style.css';
import TreeNodeContentRenderer from './TreeNodeContentRenderer';
import { Box, IconButton, Paper, Popover, TextField } from '@mui/material';
import { Add, DeleteOutline } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { HexColorPicker } from 'react-colorful';
import TreeNodeRenderer from './TreeNodeRenderer';
import ZoomPane from './ZoomPane';

const StyledTree = styled(SortableTree)`
  margin-left: -244px;
  padding: 40px;

  .rst__node {
    display: flex;
  }

  .rst__node > .rst__lineBlock:first-child {
    + .rst__nodeContent > div > div .rst__collapseButton,
    + .rst__nodeContent > div .rst__dragHandle {
      display: none;
    }

    :after,
    :before {
      display: none;
    }
  }

  .rst__rowContents {
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  .rst__rowLabel {
    padding-right: 0;
  }

  .rst__lineChildren,
  .rst__lineBlock {
    color: #ffffff;

    &:after,
    &:before {
      background-color: currentColor;
    }

    &:before {
      height: 2px;
    }

    &:after {
      width: 2px;
    }
  }

  .rst__lineBlock.rst__lineHalfVerticalTop.rst__lineHalfHorizontalRight {
    &:after {
      width: 50%;
      height: 50%;
      border-left: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      border-bottom-left-radius: 10px;
      background-color: transparent;
    }

    &:before {
      display: none;
    }
  }

  .rst__toolbarButton {
    margin-left: 16px;
  }

  .rst__nodeContent {
    position: static;
  }

  .rst__collapseButton {
    display: none;
  }

  .rst__dragHandle {
    z-index: 1;
  }
`;

interface TreeProps {
  treeData: TreeItem[];
  onChange: React.Dispatch<React.SetStateAction<TreeItem[]>>;
}

const Tree: React.FC<TreeProps> = ({ treeData, onChange }) => {
  const [popoverState, setPopoverState] = useState<{
    open: boolean;
    anchorEl?: HTMLElement | null;
    node?: any;
    path?: number[];
  }>({
    open: false,
    anchorEl: null
  });

  return (
    <ZoomPane>
      <StyledTree
        treeData={treeData}
        onChange={onChange}
        treeNodeRenderer={TreeNodeRenderer}
        nodeContentRenderer={TreeNodeContentRenderer}
        placeholderRenderer={() => null}
        rowHeight={110}
        scaffoldBlockPxWidth={244}
        generateNodeProps={({ node, path }) => {
          return {
            color: getNodeAtPath({
              treeData,
              path: path.slice(0, 1),
              getNodeKey: defaultGetNodeKey,
              ignoreCollapsed: true
            })?.node.color,
            title: (
              <Paper sx={{ p: 2 }}>
                <TextField
                  sx={{ width: 320 }}
                  label='Name'
                  value={node.title}
                  onChange={event => {
                    const title = event.target.value;

                    onChange(prevState =>
                      changeNodeAtPath({
                        treeData: prevState,
                        path,
                        getNodeKey: defaultGetNodeKey,
                        newNode: { ...node, title }
                      })
                    );
                  }}
                />
              </Paper>
            ),
            buttons: [
              <IconButton
                sx={{ color: node.color }}
                onClick={() => {
                  onChange(
                    state =>
                      addNodeUnderParent({
                        treeData: state,
                        parentKey: path[path.length - 1],
                        expandParent: true,
                        getNodeKey: defaultGetNodeKey,
                        newNode: {
                          title: ''
                        },
                        addAsFirstChild: true
                      }).treeData || state
                  );
                }}
              >
                <Add />
              </IconButton>,
              ...(path.length === 1
                ? [
                    <IconButton
                      sx={{ color: node.color }}
                      onClick={e =>
                        setPopoverState({
                          open: true,
                          anchorEl: e.currentTarget,
                          node,
                          path
                        })
                      }
                    >
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          backgroundColor: 'currentColor',
                          borderRadius: '4px'
                        }}
                      />
                    </IconButton>
                  ]
                : []),
              <IconButton
                onClick={() => {
                  onChange(state =>
                    removeNodeAtPath({
                      treeData: state,
                      path,
                      getNodeKey: defaultGetNodeKey
                    })
                  );
                }}
              >
                <DeleteOutline />
              </IconButton>
            ]
          };
        }}
      />
      <Popover
        open={popoverState.open}
        anchorEl={popoverState.anchorEl}
        onClose={() => setPopoverState({ open: false, anchorEl: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <HexColorPicker
          color={popoverState.node?.color}
          onChange={newColor => {
            onChange(prevState =>
              changeNodeAtPath({
                treeData: prevState,
                path: popoverState.path || [],
                getNodeKey: defaultGetNodeKey,
                newNode: { ...popoverState.node, color: newColor }
              })
            );
          }}
        />
      </Popover>
    </ZoomPane>
  );
};

export default Tree;
