import React, { useState } from 'react';
import { useDeleteUserMutation, useGetUsersQuery } from './usersApi';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material';
import { Add, ArrowBack, Delete, Edit } from '@mui/icons-material';
import UsersDialog from './UsersDialog';
import routes from '../routes/routes';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const navigate = useNavigate();

  const { data } = useGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();

  const [dialogState, setDialogState] = useState({ open: false, userId: '' });

  return (
    <>
      <AppBar>
        <Toolbar
          sx={{
            position: 'relative'
          }}
        >
          <Button
            variant='contained'
            color='secondary'
            startIcon={<ArrowBack />}
            onClick={() => navigate(routes.main)}
          >
            Main
          </Button>
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: `translateX(-50%)`,
              display: 'flex'
            }}
          >
            <Typography variant='h5' fontWeight={500} sx={{ mr: '20px' }}>
              All employees
            </Typography>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<Add />}
              onClick={() => setDialogState({ open: true, userId: '' })}
            >
              New employee
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Toolbar />
        <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
          <Typography
            sx={{
              color: '#fff',
              opacity: 0.6,
              textAlign: 'center',
              margin: 'auto',
              mb: 3
            }}
          >
            *Create employees. Give them access to view.
            <br />
            Employees will have access to the trees you added them to*
          </Typography>
          {data && (
            <TableContainer
              component={Paper}
              sx={{ margin: 'auto', maxWidth: 640 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Login</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.data.map(user => (
                    <TableRow key={user.id}>
                      <TableCell>{user.login}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          size='small'
                          onClick={() =>
                            setDialogState({ open: true, userId: user.id })
                          }
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => deleteUser(user.id)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
      </Box>
      <UsersDialog
        {...dialogState}
        onClose={() => setDialogState({ open: false, userId: '' })}
      />
    </>
  );
};

export default Users;
