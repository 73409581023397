import React from "react";
import {Navigate, useLocation, Outlet} from 'react-router-dom'
import routes from "./routes";
import {useSelector} from "react-redux";
import {selectAuthToken} from "../auth/authSlice";

const PrivateRoute = () => {
  const location = useLocation();

  const token = useSelector(selectAuthToken);

  if (!token) {
    return (
        <Navigate
            replace
            to={routes.login}
            state={{ from: location }}
        />
    )
  }

  return <Outlet />;
}

export default PrivateRoute;
