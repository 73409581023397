import React from 'react';
import { Menu } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const DragHandle = styled(props => (
  <div {...props}>
    <Menu color='inherit' sx={{ margin: '12px' }} />
  </div>
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '20px 16px',
  color: theme.palette.grey['50'],
  cursor: 'move',
  transition: `color ${theme.transitions.duration.standard}ms`,

  '&:hover': {
    color: theme.palette.grey['500']
  }
}));

export default DragHandle;
