import { useCallback } from 'react';
import { Blocker } from 'history';
import { useBlocker } from './usePromptBlocker';
import useConfirm from '../features/confirm/useConfirm';

const useUnsavedChangesPrompt = (when: boolean) => {
  const confirm = useConfirm();

  const blocker = useCallback<Blocker>(
    async tx => {
      if (
        await confirm({
          title: 'Are you sure you want to leave this page?',
          message:
            'There are unsaved changes. Do you really want to leave the page?',
          cancelText: 'Stay',
          continueText: 'Leave'
        })
      ) {
        tx.retry();
      }
    },
    [confirm]
  );

  useBlocker(blocker, when);
};

export default useUnsavedChangesPrompt;
