import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import IndexPage from '../index/IndexPage';
import Login from '../auth/Login';
import PrivateRoute from './PrivateRoute';
import TreePage from '../tree/TreePage';
import Users from '../users/Users';
import DiagramPage from '../diagram/DiagramPage';

const Router = () => {
  return useRoutes([
    {
      path: routes.login,
      element: <Login />
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          path: routes.main,
          element: <IndexPage />
        },
        {
          path: routes.tree(':id'),
          element: <TreePage />
        },
        {
          path: routes.users,
          element: <Users />
        },
        {
          path: routes.diagram(':id'),
          element: <DiagramPage />
        }
      ]
    }
  ]);
};

export default Router;
