import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "../../app/services/baseQuery";

export interface User {
  id: string;
  login: string;
}

interface GetUsersResponse {
  data: User[];
}

interface GetUserResponse {
  data: User;
}

export interface CreateUserData {
  login: string;
  password: string;
}

export interface UpdateUserData extends CreateUserData {
  id: string;
  login: string;
  password: string;
}

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponse, void>({
      query: (body) => ({
        body,
        url: 'users'
      }),
      providesTags: (result) =>
          result
              ? [...result.data.map(({ id }) => ({ type: 'Users' as const, id })), 'Users']
              : ['Users'],
    }),
    getUser: builder.query<GetUserResponse, string>({
      query: (id) => ({
        url: `users/${id}`
      }),
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg }]
    }),
    createUser: builder.mutation<void, CreateUserData>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'users'
      }),
      invalidatesTags: ['Users']
    }),
    updateUser: builder.mutation<void, UpdateUserData>({
      query: ({ id, ...body }) => ({
        body,
        method: 'PATCH',
        url: `users/${id}`
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.id }],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        method: 'DELETE',
        url: `users/${id}`
      }),
      invalidatesTags: ['Users']
    })
  })
})

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} = usersApi;

export default usersApi;