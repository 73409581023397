import { createSlice } from '@reduxjs/toolkit';
import authApi from '../../app/services/authApi';
import { RootState } from '../../app/store';

interface AuthState {
  token: string;
}

const initialState: AuthState = {
  token: localStorage.getItem('token') || ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: state => {
      state.token = '';
      localStorage.removeItem('token');
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.data.token;
        localStorage.setItem('token', payload.data.token);
      }
    );
  }
});

export const { logout } = authSlice.actions;

export const selectAuthToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
