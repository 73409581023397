import React from 'react';
import Router from './features/routes/Router';
import { CssBaseline } from '@mui/material';
import ToastContainer from './features/toast/ToastContainer';

function App() {
  return (
    <>
      <ToastContainer />
      <CssBaseline />
      <Router />
    </>
  );
}

export default App;
