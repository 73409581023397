import React from 'react';
import {Checkbox as MuiCheckbox, CheckboxProps} from "@mui/material";
import checkbox from './checkbox.png';
import {styled} from "@mui/material/styles";

const CheckboxIcon = styled('span')({
  width: 24,
  height: 24,
  backgroundImage: `url(${checkbox})`,
  backgroundSize: 'contain'
})

const Checkbox = (props: CheckboxProps) => {
  return (
      <MuiCheckbox
          {...props}
          disableRipple
          icon={<CheckboxIcon />}
          checkedIcon={<CheckboxIcon />}
          sx={{
            p: 0,
            ...props.sx
          }}
      />
  );
};

export default Checkbox;