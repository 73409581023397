import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEdgesState, useNodesState } from 'react-flow-renderer';

import {
  useDeleteMainTreeMutation,
  useGetMainTreeQuery
} from '../../app/services/treeApi';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import UsersSidebar, { userSidebarWidth } from './UsersSidebar';
import Diagram from './Diagram';
import {
  UpdateUserTreeNodes,
  useGetUserTreeByMainQuery,
  useUpdateUserTreeMutation
} from '../../app/services/treeApi';
import getLayoutedElements from './graph';
import { ArrowBack, Delete } from '@mui/icons-material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import routes from '../routes/routes';
import { DiagramNodeData, DiagramNodeOnChange, mapTreeToNodes } from './utils';
import toast from '../toast';
import { useGetUsersQuery } from '../users/usersApi';
import useConfirm from '../confirm/useConfirm';
import useUnsavedChangesPrompt from '../../hooks/useUnsavedChangesPrompt';

const DiagramPage = () => {
  const confirm = useConfirm();

  const navigate = useNavigate();
  const params = useParams();
  const id = params.id as string;

  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isSuccess: isUsersFetched } = useGetUsersQuery();
  const users = data?.data || [];

  const userId = (searchParams.get('userId') as string) || users[0]?.id;

  const { data: mainTree } = useGetMainTreeQuery(id, {
    skip: !isUsersFetched || !!userId
  });
  const { data: userTree } = useGetUserTreeByMainQuery(
    { mainTreeId: id, userId },
    { skip: !isUsersFetched || !userId }
  );

  const [deleteTree] = useDeleteMainTreeMutation();

  const treeData = userTree || mainTree;

  const isNodesLayouted = useRef(false);

  const [nodes, setNodes] = useNodesState<DiagramNodeData>([]);
  const [edges, setEdges] = useEdgesState([]);

  const [unsavedData, setUnsavedData] = useState<UpdateUserTreeNodes>({});

  const haveUnsavedData = Boolean(Object.keys(unsavedData).length);

  useUnsavedChangesPrompt(haveUnsavedData);

  const onChange = useCallback<DiagramNodeOnChange>(
    (id, data) => {
      if (userId) {
        setNodes(prevNodes =>
          prevNodes.map(node => {
            if (node.id === id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  ...data
                }
              };
            }

            return node;
          })
        );

        setUnsavedData(prevState => ({
          ...prevState,
          [id]: data
        }));
      }
    },
    [userId, setNodes]
  );

  useEffect(() => {
    if (treeData) {
      const { initialNodes, initialEdges } = mapTreeToNodes(
        // @ts-ignore
        { ...treeData, id: treeData.mainTreeId || treeData.id },
        {
          onChange
        }
      );

      if (isNodesLayouted.current) {
        setNodes(prevNodes =>
          initialNodes.map((node, index) => ({
            ...prevNodes[index],
            data: node.data
          }))
        );
      } else {
        const { nodes: layoutedNodes, edges: layoutedEdges } =
          getLayoutedElements(initialNodes, initialEdges);

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);

        isNodesLayouted.current = true;
      }
    }
  }, [treeData, onChange, setEdges, setNodes]);

  const [updateUserTree] = useUpdateUserTreeMutation();

  const saveDiagram = useCallback(async () => {
    try {
      await updateUserTree({
        userId,
        mainTreeId: id,
        userTreeNodes: unsavedData
      }).unwrap();

      setUnsavedData({});
      toast.success('Saved');
    } catch (error: any) {
      toast.error(error.data.error.message);
    }
  }, [id, unsavedData, updateUserTree, userId]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (haveUnsavedData) {
      timer = setTimeout(() => {
        saveDiagram();
      }, 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [haveUnsavedData, saveDiagram]);

  return (
    <Box sx={{ display: 'flex' }}>
      <UsersSidebar
        users={users}
        userId={userId}
        onChange={userId => setSearchParams({ userId }, { replace: true })}
      />
      <AppBar
        sx={{
          marginLeft: userSidebarWidth,
          width: `calc(100% - ${userSidebarWidth}px)`
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center'
          }}
        >
          <Button
            color='secondary'
            variant='contained'
            onClick={() => navigate(routes.main)}
            startIcon={<ArrowBack />}
            sx={{
              position: 'absolute',
              left: 20
            }}
          >
            Main
          </Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant='h5' fontWeight={500}>
              Tree «{treeData?.title}»
            </Typography>
            <Button
              color='secondary'
              variant='contained'
              onClick={() => navigate(routes.tree(id))}
              sx={{
                mx: '20px'
              }}
            >
              Edit roadmap
            </Button>
            <IconButton
              onClick={async () => {
                if (
                  await confirm('Are you sure you want to delete the tree?')
                ) {
                  try {
                    await deleteTree(id).unwrap();
                    toast.success('Tree removed');
                    navigate(routes.main);
                  } catch (e: any) {
                    toast.error(e.data.error.message);
                  }
                }
              }}
            >
              <Delete />
            </IconButton>
          </Box>
          <Button
            onClick={saveDiagram}
            variant='contained'
            sx={{
              position: 'absolute',
              right: 20
            }}
          >
            Save changes
          </Button>
        </Toolbar>
      </AppBar>
      <Diagram nodes={nodes} edges={edges} />
    </Box>
  );
};

export default DiagramPage;
