import React, { memo, useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { Box } from '@mui/material';
import Checkbox from './Checkbox';
import StatusSelect from './StatusSelect';
import { DiagramNodeData } from './utils';
import { UserTreeNodeStatus } from '../../app/services/treeApi';

const StatusSelectNode = memo(({ data, id }: NodeProps<DiagramNodeData>) => {
  const [checked, setChecked] = useState(!!data.status);

  const handleCheck = () =>
    setChecked(prevState => {
      if (!data.onChange) {
        return prevState;
      }

      if (prevState && data.status) {
        data.onChange(id, { status: null });
      }

      if (!prevState && !data.status) {
        data.onChange(id, { status: UserTreeNodeStatus.normal });
      }

      return !prevState;
    });

  const handleChangeStatus = (status: UserTreeNodeStatus) => {
    if (data.onChange) {
      data.onChange(id, { status });
    }
  };

  useEffect(() => {
    setChecked(!!data.status);
  }, [data.status]);

  return (
    <>
      <Handle type='target' position={Position.Left} style={{ opacity: 0 }} />
      <Box
        sx={{
          position: 'relative',
          ml: '-6px',
          display: 'flex',
          alignItems: 'center',
          height: 35,
          '&:hover .MuiCheckbox-root': {
            display: 'inline-flex'
          }
        }}
      >
        <Box
          component='label'
          sx={theme => ({
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            height: 1,
            padding: '0 16px',
            margin: 0,
            border: '2px solid',
            borderRadius: checked ? '8px 0 0 8px' : '8px',
            borderColor: checked
              ? data.color || theme.palette.primary.main
              : '#DAE4ED',
            background: checked
              ? data.color || theme.palette.primary.main
              : '#FBFBFB',
            cursor: 'pointer'
          })}
        >
          <Box
            component='span'
            sx={{
              fontSize: 16,
              fontWeight: 600,
              color: '#202020'
            }}
          >
            {data.title}
          </Box>
          {!data.isRoot && (
            <Checkbox
              onChange={handleCheck}
              checked={checked}
              sx={{ display: checked ? 'inline-flex' : 'none', ml: '10px' }}
            />
          )}
        </Box>
        {checked && (
          <StatusSelect status={data.status} onChange={handleChangeStatus} />
        )}
      </Box>
      {data.hasChildren && (
        <Handle
          type='source'
          position={Position.Right}
          style={{
            width: 12,
            height: 12,
            right: -6,
            borderWidth: 3,
            borderColor: data.color,
            backgroundColor: '#fff'
          }}
        />
      )}
    </>
  );
});

export default StatusSelectNode;
