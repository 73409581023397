import { createApi } from "@reduxjs/toolkit/dist/query/react";
import baseQuery from "./baseQuery";

interface LoginResponse {
  data: {
    token: string;
  }
}

export interface LoginData {
  login: string;
  password: string;
}

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginData>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'admins/login'
      })
    })
  })
})

export const { useLoginMutation } = authApi;

export default authApi;