import { toast as reactToastify } from 'react-toastify';
import { ToastContent, ToastOptions } from 'react-toastify/dist/types';

const toast = { ...reactToastify };

toast.success = (content: ToastContent, options?: ToastOptions) => {
  return reactToastify.success(content, { autoClose: 3500, ...options });
};

export default toast;
