import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { ConfirmProps } from './ConfirmTypes';

const Confirm = ({
  title,
  message,
  cancelText = 'Cancel',
  continueText = 'OK',
  open,
  onClose
}: ConfirmProps) => {
  return (
    <Dialog open={open} onClose={() => onClose('cancel')}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose('cancel')}
          variant='contained'
          color='secondary'
          sx={{ mr: '10px' }}
        >
          {cancelText}
        </Button>
        <Button onClick={() => onClose('continue')} variant='contained'>
          {continueText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
