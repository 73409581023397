import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';

export type CardMenuActions = 'delete';

export interface CardMenuProps {
  onClick: (action: CardMenuActions) => void;
}

const options: { label: string; action: CardMenuActions }[] = [
  { label: 'Delete', action: 'delete' }
];

const CardMenu = ({ onClick }: CardMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (action: CardMenuActions) => {
    onClick(action);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} sx={{ p: 0 }} disableRipple>
        <MoreVertIcon fontSize='inherit' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{ mt: '10px' }}
      >
        {options.map(option => (
          <MenuItem
            key={option.action}
            onClick={() => handleOptionClick(option.action)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CardMenu;
