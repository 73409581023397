import React from 'react';
import {
  ToastContainer as ReactToastifyContainer,
  ToastContainerProps
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

const iconRenderer = ({ type }: { type: 'success' | 'error' }) => {
  switch (type) {
    case 'success':
      return <CheckCircleOutline color='success' />;
    case 'error':
      return <ErrorOutline color='error' />;
    default:
      return false;
  }
};

const StyledContainer = styled(
  ({ theme, ...restProps }: ToastContainerProps) => (
    <ReactToastifyContainer {...restProps} icon={iconRenderer} />
  )
)(({ theme }) => ({
  '&.Toastify__toast-container--top-right': {
    top: 104,
    right: 24
  },
  '&.Toastify__toast-theme--colored.Toastify__toast--success': {
    backgroundColor: theme.palette.success.light
  },
  '&.Toastify__toast-theme--colored.Toastify__toast--error': {
    backgroundColor: theme.palette.error.light
  },
  '.Toastify__toast-icon': {
    width: 24,
    marginRight: 12
  }
}));

const ToastContainer = ({ theme, ...restProps }: ToastContainerProps) => {
  return (
    <StyledContainer
      hideProgressBar
      autoClose={8000}
      closeButton={false}
      {...restProps}
    />
  );
};

export default ToastContainer;
