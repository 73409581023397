import dagre from "dagre";
import {Edge, Node} from "react-flow-renderer";

const nodeWidth = 200;
const nodeHeight = 44;

const getLayoutedElements = (nodes: Node[], edges: Edge[], offset = 0) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({
    rankdir: 'LR',
    ranksep: 300,
    nodesep: 13
  });

  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, {width: nodeWidth, height: nodeHeight});
  });

  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any, i) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'left';
    node.sourcePosition = 'right';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: (nodeWithPosition.y - nodeHeight / 2) + offset,
    };

    return node;
  });

  return {nodes, edges};
};

export default getLayoutedElements;
