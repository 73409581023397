import React from 'react';
import { Button } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

const Support = () => {
  return (
    <Button
      component='a'
      target='_blank'
      href='https://t.me/SkillsTree'
      variant='text'
      startIcon={<HelpOutline />}
      sx={{
        color: 'white'
      }}
    >
      Write to us
    </Button>
  );
};

export default Support;
