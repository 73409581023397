import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import { AppBar, Box, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import routes from '../routes/routes';

interface TreePageLayoutProps {
  onSave?: () => void;
  id: string;
}

const TreePageLayout: React.FC<TreePageLayoutProps> = ({
  children,
  onSave,
  id
}) => {
  const navigate = useNavigate();

  const isNewTree = id === 'new';

  const backButtonText = isNewTree ? 'Main' : 'To the tree';

  const handleBackButtonClick = () => {
    if (isNewTree) {
      navigate(routes.main);
    } else {
      navigate(routes.diagram(id));
    }
  };

  return (
    <>
      <AppBar>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ArrowBack />}
            onClick={handleBackButtonClick}
          >
            {backButtonText}
          </Button>
          {onSave && (
            <Button
              onClick={onSave}
              type='submit'
              variant='contained'
              form='tree-form'
            >
              Save
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component='main'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100vh',
          width: 1,
          overflow: 'hidden'
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
};

export default TreePageLayout;
