import React from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { UserTreeNodeStatus } from '../../app/services/treeApi';

interface StatusSelectProps {
  status?: UserTreeNodeStatus | null;
  onChange: (status: UserTreeNodeStatus) => void;
}

const StatusSelect = ({ status, onChange }: StatusSelectProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        component='button'
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          height: 1,
          padding: '0 16px',
          background: '#484848',
          borderRadius: '0 11px 11px 0',
          border: 'none',
          color: '#fff'
        }}
        onClick={handleClick}
      >
        <Box
          component='span'
          sx={{
            mr: '10px',
            fontSize: 14,
            fontWeight: 600
          }}
        >
          {status}
        </Box>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            marginTop: '5px',
            width: anchorEl?.offsetWidth
          }
        }}
      >
        {Object.values(UserTreeNodeStatus).map(item => (
          <MenuItem
            key={item}
            value={item}
            onClick={() => {
              onChange(item);
              handleClose();
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StatusSelect;
