import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export interface TreeNode {
  title: string;
  color?: string;
  nodes?: TreeNode[];
}

export interface TreeNodeWithId extends TreeNode {
  id: string;
  nodes?: TreeNodeWithId[];
}

export interface CreateTreeResponse {
  data: { _id: string };
}

export interface GetMainTreesResponse {
  data: TreeNodeWithId[];
}

export enum UserTreeNodeStatus {
  forgot = 'Forgot',
  normal = 'Normal',
  pro = 'Pro'
}

export interface UserTreeNode {
  color: string;
  nodes: UserTreeNode[];
  status: UserTreeNodeStatus | null;
  title: string;
  id: string;
}

interface GetUserTreesParams {
  userId: string;
}

export interface UpdateUserTreeNodes {
  [key: string]: { status: UserTreeNodeStatus | null };
}

interface UpdateUserTreeData {
  userId: string;
  mainTreeId: string;
  userTreeNodes: UpdateUserTreeNodes;
}

interface GetUserTreeByMainParams {
  userId: string;
  mainTreeId: string;
}

interface GetUserTreeByMainResponse extends UserTreeNode {
  mainTreeId: string;
  updatedAt: string;
  userId: string;
}

const treeApi = createApi({
  reducerPath: 'treeApi',
  baseQuery,
  tagTypes: ['MainTree', 'UserTree'],
  endpoints: builder => ({
    getMainTrees: builder.query<GetMainTreesResponse, void>({
      query: () => 'main-trees',
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'MainTree' as const,
                id
              })),
              'MainTree'
            ]
          : ['MainTree']
    }),
    getMainTree: builder.query<TreeNodeWithId, string>({
      query: id => `main-trees/${id}`,
      transformResponse: (response: { data: TreeNodeWithId }) => response.data,
      providesTags: (result, error, arg) => [{ type: 'MainTree', id: arg }]
    }),
    createMainTree: builder.mutation<CreateTreeResponse, TreeNode>({
      query: body => ({
        body,
        method: 'POST',
        url: 'main-trees'
      }),
      invalidatesTags: ['MainTree']
    }),
    updateMainTree: builder.mutation<void, TreeNodeWithId>({
      query: ({ id, ...body }) => ({
        body,
        method: 'PATCH',
        url: `main-trees/${id}`
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'MainTree', id: arg.id },
        'UserTree'
      ]
    }),
    deleteMainTree: builder.mutation<void, string>({
      query: id => ({
        method: 'DELETE',
        url: `main-trees/${id}`
      }),
      invalidatesTags: ['MainTree']
    }),
    getUserTrees: builder.query<void, GetUserTreesParams>({
      query: params => ({
        params,
        url: 'user-trees'
      })
    }),
    updateUserTree: builder.mutation<void, UpdateUserTreeData>({
      query: body => ({
        body,
        method: 'PATCH',
        url: 'user-trees'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserTree', id: arg.mainTreeId }
      ]
    }),
    getUserTreeByMain: builder.query<
      GetUserTreeByMainResponse,
      GetUserTreeByMainParams
    >({
      query: params => ({
        params,
        url: 'user-trees/by-main'
      }),
      transformResponse: (response: { data: GetUserTreeByMainResponse }) => {
        return response.data;
      },
      providesTags: (result, error, arg) => [
        { type: 'UserTree', id: arg.mainTreeId },
        'UserTree'
      ]
    })
  })
});

export const {
  useGetMainTreeQuery,
  useGetMainTreesQuery,
  useLazyGetMainTreeQuery,
  useCreateMainTreeMutation,
  useUpdateMainTreeMutation,
  useDeleteMainTreeMutation,
  useGetUserTreesQuery,
  useUpdateUserTreeMutation,
  useGetUserTreeByMainQuery,
  useLazyGetUserTreeByMainQuery
} = treeApi;

export default treeApi;
