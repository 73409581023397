import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  typography: {
    fontFamily: 'Inter, sans-serif'
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#1F1F1F',
      paper: '#2C2C2C'
    },
    primary: {
      main: '#E76940',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#484848'
    }
  }
});

const darkTheme = createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none'
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 12,
            borderRadius: 8,
            backgroundColor: theme.palette.background.paper
          }
        }
      }
    }
  },
  theme
);

export default darkTheme;
