import React from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoginData, useLoginMutation } from '../../app/services/authApi';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from '../toast';

const LoginPage = () => {
  const [login] = useLoginMutation();

  const navigate = useNavigate();
  const location = useLocation();

  // @ts-ignore
  const from = location.state?.from?.pathname || '/';

  const { handleSubmit, register } = useForm<LoginData>();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1
      }}
    >
      <Paper sx={{ p: 2, mt: 14 }}>
        <form
          onSubmit={handleSubmit(async data => {
            try {
              await login(data).unwrap();
              navigate(from);
            } catch (e) {
              toast.error('Login error');
            }
          })}
        >
          <Typography variant='h4' component='h1'>
            Authorization
          </Typography>
          <TextField
            {...register('login')}
            fullWidth
            margin='normal'
            label='Login'
          />
          <TextField
            {...register('password')}
            fullWidth
            margin='normal'
            label='Password'
            name='password'
            type='password'
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button type='submit' size='large' variant='contained'>
              Login
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default LoginPage;
