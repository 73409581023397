import {getEdgeCenter, getMarkerEnd, getSmoothStepPath} from "react-flow-renderer";
import React, {useEffect, useState} from "react";

const CustomEdge = (
    {
      id,
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
      style = {},
      arrowHeadType,
      markerEndId,
    }: any
) => {
  const [center, setCenter] = useState<[number, number] | undefined>();

  // fix edge center
  useEffect(() => {
    if (center) {
      return;
    }

    const [centerX, centerY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY
    });

    setCenter([centerX, centerY]);
  }, [
    center,
    sourceX,
    sourceY,
    targetX,
    targetY
  ])

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 20,
    centerX: center ? center[0] : undefined,
    centerY: center ? center[1] : undefined,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
      <>
        <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd}/>
      </>
  );
}

export default CustomEdge;
