import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material';
import { Add, GroupOutlined, Logout } from '@mui/icons-material';
import {
  useDeleteMainTreeMutation,
  useGetMainTreesQuery
} from '../../app/services/treeApi';
import { Link, useNavigate } from 'react-router-dom';
import routes from '../routes/routes';
import EmptyMessage from './EmptyMessage';
import placeholder from '../../images/placeholder.png';
import Logo from '../../components/Logo';
import { logout } from '../auth/authSlice';
import useConfirm from '../confirm/useConfirm';
import { useDispatch } from 'react-redux';
import Support from '../../components/Support';
import CardMenu, { CardMenuActions } from '../../components/CardMenu';
import toast from '../toast';

const IndexPage = () => {
  const dispatch = useDispatch();

  const confirm = useConfirm();

  const navigate = useNavigate();
  const { data, isFetching } = useGetMainTreesQuery();
  const [deleteMainTree] = useDeleteMainTreeMutation();

  const handleCartMenuClick = async (action: CardMenuActions, id: string) => {
    try {
      if (action === 'delete') {
        if (await confirm('Are you sure you want to delete the tree?')) {
          await deleteMainTree(id).unwrap();
          toast.success('Tree removed');
        }
      }
    } catch (e: any) {
      toast.error(e.data.error.message);
    }
  };

  return (
    <>
      <AppBar>
        <Toolbar>
          <Logo />
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
            <Support />
            <Button
              color='secondary'
              variant='contained'
              startIcon={<GroupOutlined />}
              sx={{ ml: '24px' }}
              onClick={() => navigate(routes.users)}
            >
              All employees
            </Button>
            <IconButton
              onClick={async () => {
                if (await confirm('Are you sure you want to leave?')) {
                  dispatch(logout());
                }
              }}
              sx={{ ml: '10px' }}
            >
              <Logout />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component='main'
        sx={{
          position: 'relative',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 4, mb: 4, maxWidth: '1872px' }}>
          <Grid container direction='column' spacing={4}>
            <Grid item container alignItems='center' spacing={4}>
              <Grid item>
                <Typography variant='h4'>Your trees</Typography>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  to={routes.tree('new')}
                  variant='contained'
                  endIcon={<Add />}
                  color='secondary'
                >
                  New tree
                </Button>
              </Grid>
            </Grid>
            {isFetching || data?.data.length ? (
              <Grid item container spacing={3}>
                {data?.data.map(card => (
                  <Grid item key={card.id} xs={12} sm={6} md={4} xl={2}>
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 1,
                        border: '2px solid #2C2C2C',
                        borderRadius: '10px',
                        textDecoration: 'none'
                      }}
                    >
                      <CardActionArea
                        component={Link}
                        to={routes.diagram(card.id)}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: 1
                        }}
                      >
                        <CardMedia image={placeholder} component='img' />
                      </CardActionArea>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: 1,
                          mt: 'auto',
                          px: '16px',
                          pt: '16px',
                          pb: '16px !important'
                        }}
                      >
                        <Typography variant='body1'>{card.title}</Typography>
                        <CardMenu
                          onClick={action =>
                            handleCartMenuClick(action, card.id)
                          }
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <EmptyMessage />
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default IndexPage;
