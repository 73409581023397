import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';

const ZoomPane: React.FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const pane = useRef({ pos: { x: 0, y: 0 }, scale: 1 });

  const [showReturn, setShowReturn] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const container = ref.current;
    const target = container.firstElementChild as HTMLDivElement;

    let dragStarted = false;
    let lastMousePos = { x: 0, y: 0 };

    const update = () => {
      target.style.transform = `translate(${pane.current.pos.x}px, ${pane.current.pos.y}px) scale(${pane.current.scale}, ${pane.current.scale})`;

      setShowReturn(pane.current.pos.x !== 0 || pane.current.pos.y !== 0);
    };

    const handleWheel = (event: WheelEvent) => {
      if (!container) {
        return null;
      }

      event.preventDefault();

      if (event.ctrlKey || event.metaKey) {
        let delta = event.deltaY;
        delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

        const rect = container.getBoundingClientRect();
        const offsetLeft = rect.left + window.scrollX;
        const offsetTop = rect.top + window.scrollY;

        const zoomPointX = event.pageX - offsetLeft;
        const zoomPointY = event.pageY - offsetTop;

        const zoomTargetX =
          (zoomPointX - pane.current.pos.x) / pane.current.scale;
        const zoomTargetY =
          (zoomPointY - pane.current.pos.y) / pane.current.scale;

        pane.current.scale += delta * -0.05 * pane.current.scale;
        pane.current.scale = Math.max(0.1, Math.min(1, pane.current.scale));

        pane.current.pos.x = -zoomTargetX * pane.current.scale + zoomPointX;
        pane.current.pos.y = -zoomTargetY * pane.current.scale + zoomPointY;
      } else {
        const { deltaX, deltaY } = event;

        pane.current.pos.x -= deltaX;
        pane.current.pos.y -= deltaY;
      }

      update();
    };

    const handleMouseDown = (event: MouseEvent) => {
      if (event.button !== 1) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      dragStarted = true;
      lastMousePos = { x: event.pageX, y: event.pageY };
      target.style.cursor = 'grab';
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!dragStarted) {
        return;
      }

      const currentMousePos = { x: event.pageX, y: event.pageY };

      const deltaX = currentMousePos.x - lastMousePos.x;
      const deltaY = currentMousePos.y - lastMousePos.y;

      lastMousePos = currentMousePos;

      pane.current.pos.x += deltaX * 1.3;
      pane.current.pos.y += deltaY * 1.3;

      update();
    };

    const handleMouseUp = () => {
      dragStarted = false;
      target.style.cursor = 'default';
    };

    container.addEventListener('wheel', handleWheel, { passive: false });

    container.addEventListener('mousedown', handleMouseDown);
    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseup', handleMouseUp);

    return () => {
      container.removeEventListener('wheel', handleWheel);

      container.removeEventListener('mousedown', handleMouseDown);
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <div style={{ position: 'absolute', transformOrigin: '0 0' }}>
        {children}
      </div>

      {showReturn && (
        <Button
          variant='contained'
          color='secondary'
          onClick={() => {
            (
              ref.current?.firstElementChild as HTMLDivElement
            ).style.transform = `translate(0px, 0px) scale(1, 1)`;
            pane.current.pos.x = 0;
            pane.current.pos.y = 0;
            pane.current.scale = 1;
            setShowReturn(false);
          }}
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            top: 32,
            zIndex: 10
          }}
        >
          Return to start
        </Button>
      )}
    </div>
  );
};

export default ZoomPane;
