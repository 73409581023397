import React from 'react';
import ReactFlow, {Edge, Node} from "react-flow-renderer";
import {Box} from "@mui/material";
import CustomEdge from "./CustomEdge";
import StatusSelectNode from "./StatusSelectNode";

const edgeTypes = {
  custom: CustomEdge
}

const nodeTypes = {
  statusSelect: StatusSelectNode
}

interface DiagramProps {
  nodes: Node[];
  edges: Edge[];
}

const Diagram = ({ nodes, edges }: DiagramProps) => {
  return (
      <Box sx={{
        position: 'relative',
        flexGrow: 1,
        height: '100vh'
      }}>
        <ReactFlow
            panOnScroll={true}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            fitView
        />
      </Box>
  );
};

export default Diagram;