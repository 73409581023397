import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";

interface AppState {
  isDiagramView?: boolean;
}

const initialState: AppState = {
  isDiagramView: false
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleDiagramView: state => {
      state.isDiagramView = !state.isDiagramView;
    }
  }
})

export const selectIsDiagramView = (state: RootState) => state.app.isDiagramView;

export const { toggleDiagramView } = appSlice.actions;

export default appSlice.reducer;
