import React from 'react';
import {Divider, Drawer, List, ListItem, ListItemText, Toolbar} from "@mui/material";
import {User} from "../users/usersApi";
import Logo from "../../components/Logo";

export const userSidebarWidth = 240;

interface UserSidebarProps {
  users: User[];
  userId?: string;
  onChange: (userId: string) => void;
}

const UsersSidebar = ({users, userId, onChange}: UserSidebarProps) => {
  return (
      <Drawer
          variant='permanent'
          anchor='left'
          sx={{
            width: userSidebarWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: userSidebarWidth,
              boxSizing: 'border-box',
            },
          }}
      >
        <Toolbar>
          <Logo/>
        </Toolbar>
        <Divider/>
        <List>
          {users.map(user => (
              <ListItem
                  button
                  key={user.id}
                  onClick={() => onChange(user.id)}
                  sx={user.id === userId ? {
                    background: '#484848',
                    color: 'primary.main'
                  } : {}}>
                <ListItemText primary={user.login} primaryTypographyProps={{fontWeight: 600}}/>
              </ListItem>
          ))}
        </List>
      </Drawer>
  );
};

export default UsersSidebar;