import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (token) {
      headers.set('x-access-token', token);
    }

    return headers;
  }
});

// remove undefined, null, empty string values
const clearParams = (params: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};

  for (const paramsKey in params) {
    if (params[paramsKey] || params[paramsKey] === 0) {
      result[paramsKey] = params[paramsKey];
    }
  }

  return result;
};

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
    async (args, api, extraOptions) => {
      let adjustedParams = typeof args === 'string' ? undefined : args.params;

      if (typeof adjustedParams === 'object') {
        adjustedParams = clearParams(adjustedParams);
      }

      const adjustedArgs =
          typeof args === 'string' ? args : { ...args, params: adjustedParams };
      // provide the amended url and other params to the raw base query
      return rawBaseQuery(adjustedArgs, api, extraOptions);
    };

export default baseQuery;