import React from 'react';
import {Box, Typography} from "@mui/material";

const EmptyMessage = () => {
  return (
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: 1,
          height: 1,
          display: 'flex',
          justifyContent: 'center',
          pointerEvents: 'none'
        }}
      >
        <Typography
            variant='h3'
            sx={{
              alignSelf: 'center',
              color: '#FFFFFF',
              opacity: 0.5
            }}
        >
          No roadmaps yet
        </Typography>
      </Box>
  );
};

export default EmptyMessage;