import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  CreateUserData,
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation
} from './usersApi';
import toast from '../toast';

interface UsersDialogProps {
  userId?: string;
  open: boolean;
  onClose: () => void;
}

const defaultValues = { login: '', password: '' };

const UsersDialog = ({ userId, open, onClose }: UsersDialogProps) => {
  const { reset, handleSubmit, control } = useForm<CreateUserData>({
    defaultValues
  });

  const { data } = useGetUserQuery(userId as string, { skip: !userId });

  useEffect(() => {
    if (userId && data) {
      reset({
        ...defaultValues,
        login: data.data.login
      });
    } else {
      reset(defaultValues);
    }
  }, [userId, data, reset]);

  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
      <DialogTitle>{userId ? 'Update employee' : 'New employee'}</DialogTitle>
      <DialogContent>
        <form
          id='user-form'
          onSubmit={handleSubmit(async data => {
            try {
              if (userId) {
                await updateUser({ id: userId, ...data }).unwrap();
              } else {
                await createUser(data).unwrap();
              }
              onClose();
            } catch (e: any) {
              toast.error(e.data.error.message);
            }
          })}
        >
          <Stack spacing={5} sx={{ pt: 2 }}>
            <Controller
              name='login'
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  label='Login'
                />
              )}
            />
            <Controller
              name='password'
              control={control}
              render={({ field }) => (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  label='Password'
                />
              )}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type='submit' form='user-form'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersDialog;
