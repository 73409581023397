import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  useCreateMainTreeMutation,
  useLazyGetMainTreeQuery,
  useUpdateMainTreeMutation
} from '../../app/services/treeApi';
import { useParams } from 'react-router-dom';
import { TreeItem } from 'react-sortable-tree';
import { mapTreeItemsToNodes, mapTreeNodesToItems } from './utils';
import TreePageLayout from './TreePageLayout';
import { Box, IconButton, Input } from '@mui/material';
import { Add } from '@mui/icons-material';
import Tree from './Tree';
import toast from '../toast';
import useUnsavedChangesPrompt from '../../hooks/useUnsavedChangesPrompt';
import useMount from '../../hooks/useMount';

const TreePage = () => {
  const params = useParams();

  const [id, setId] = useState(params.id as string);
  const isNewTree = id === 'new';

  const [getMainTree, { data }] = useLazyGetMainTreeQuery();

  useMount(() => {
    if (!isNewTree) {
      getMainTree(id);
    }
  });

  const [createTree] = useCreateMainTreeMutation();
  const [updateTree] = useUpdateMainTreeMutation();

  const [treeData, setTreeData] = useState<TreeItem[]>([]);
  const [treeName, setTreeName] = useState('');

  const [hasUnsavedData, setHasUnsavedData] = useState(false);

  useUnsavedChangesPrompt(hasUnsavedData);

  const handleChangeTree = (action: SetStateAction<TreeItem[]>) => {
    setTreeData(action);
    setHasUnsavedData(true);
  };

  useEffect(() => {
    if (data) {
      setTreeData(mapTreeNodesToItems(data.nodes || []));
      setTreeName(data.title);
    }
  }, [data]);

  const saveTree = useCallback(async () => {
    try {
      if (isNewTree) {
        const response = await createTree({
          title: treeName,
          nodes: mapTreeItemsToNodes(treeData)
        }).unwrap();

        setId(response.data._id);
      } else {
        await updateTree({
          id,
          title: treeName,
          // @ts-ignore
          nodes: mapTreeItemsToNodes(treeData)
        }).unwrap();
      }

      setHasUnsavedData(false);

      toast.success('Saved');
    } catch (error: any) {
      toast.error(error.data.error.message);
    }
  }, [createTree, id, isNewTree, treeData, updateTree, treeName]);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (hasUnsavedData) {
      timer = setTimeout(() => {
        saveTree();
      }, 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [hasUnsavedData, saveTree]);

  return (
    <TreePageLayout onSave={saveTree} id={id}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, px: 5, pt: 5 }}>
        <Input
          placeholder='Tree name'
          value={treeName}
          onChange={event => {
            setTreeName(event.target.value);
            setHasUnsavedData(true);
          }}
        />
        <IconButton
          onClick={() => {
            setTreeData(prevState => [{ title: '' }, ...prevState]);
          }}
          sx={{ ml: '40px' }}
        >
          <Add />
        </IconButton>
      </Box>
      <Tree treeData={treeData} onChange={handleChangeTree} />
    </TreePageLayout>
  );
};

export default TreePage;
