import React, { createContext, useCallback, useRef, useState } from 'react';
import Confirm from './Confirm';
import { ConfirmContextValue, ConfirmOptions } from './ConfirmTypes';

export const ConfirmContext = createContext<ConfirmContextValue>(
  {} as ConfirmContextValue
);

const ConfirmProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmOptions>();

  const promiseResolve = useRef<(value: boolean) => void>(() => {});

  const close = useCallback<ConfirmContextValue['close']>(reason => {
    setOpen(false);

    if (reason === 'continue') {
      promiseResolve.current(true);
    } else {
      promiseResolve.current(false);
    }
  }, []);

  const open = useCallback<ConfirmContextValue['open']>(payload => {
    setOpen(true);
    setOptions(typeof payload === 'string' ? { message: payload } : payload);

    return new Promise(resolve => {
      promiseResolve.current = resolve;
    });
  }, []);

  return (
    <ConfirmContext.Provider value={{ open, close, setOptions }}>
      {children}
      <Confirm open={isOpen} onClose={close} {...options} />
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
